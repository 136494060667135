import React, { useState, useEffect } from "react";
import Grid from "../../Grid/Grid";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import DropDownGrid from "../../Grid/Components/DropDownGrid";
import {
  Box,
  MenuItem,
  ListItemIcon,
  Typography
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import transactionAuditAPI from "../../../Network/TransactionAudit";
import { selectRoles } from "../../../reduxStore/RolesReducer";

const cookies = new Cookies();



export default function TransactionAudits(props) {
  const [t] = useTranslation("common");
  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);

  const [pageCount, setPageCount] = useState(0);
  const [openFilterColumn, setOpenFilterColumn] = useState(false);
  const [openFilterColumnValue, setOpenFilterColumnValue] = useState([]);



  
  const [filters, setFilters] = useState({
    name: "",
    code: "",
    type_id: null,
    wialon_id: null,
    export_excel: false
  })
  const [reload, setReload] = useState(false)



  const handlePageChange = (newPage) => {
    setPageNumber(newPage + 1);
  };
  const navigate = useNavigate();
  const controller = new AbortController();
  const [rowsTotal, setRowsTotal] = useState(0);
  const screenwidth = useSelector((state) => state.settingsData.screenwidth);

  const rolesRedux = useSelector(selectRoles)

  const columns = [
    { field: "index_column", headerName: "ت", minWidth: 50, maxWidth: 60 },
    // { field: "id", headerName: "#", minWidth: 50, maxWidth: 60 },
    { field: "transaction_status_name", headerName: t("TRANSACTIONS.transaction_status_name"), flex: 1 },
    { field: "note", headerName: t("TRANSACTIONS.note"),  flex: 2 },
    { field: "created_by_name", headerName: t("TRANSACTIONS.audit_created_by_name"), flex: 1 },
    { field: "formatted_datetime", headerName: t("TRANSACTIONS.audit_created_at"),  flex: 1},

    // {
    //   headerName: t("TRANSACTIONS.actions"),
    //   field: "Actions",
    //   flex: 0.4,
    //   minWidth: 100,
    //   renderHeader: () => (
    //     <Typography>
    //       {rolesRedux?.update_transaction_audit?.value ? t("TRANSACTIONS.actions") : null}
    //     </Typography>
    //   ),
    //   renderCell: (rows) => (
    //     rolesRedux?.update_transaction_audit?.value ?
    //       <DropDownGrid>
    //         <MenuItem onClick={() => { updateFunction(rows?.row) }} className="ActionIcons">
    //           <ListItemIcon sx={{
    //             display: 'flex',
    //             justifyContent: 'center',
    //             margin: '0px 7px 0 0'
    //           }}>
    //             <EditIcon style={{ fill: "#17681b" }} />
    //           </ListItemIcon>
    //           {t('TRANSACTIONS.update_title')}
    //         </MenuItem>


    //       </DropDownGrid> : null
    //   ),
    // },
  ]





  useEffect(() => {
    loadData()
  }, [pageNumber, pageSize, reload, props?.reload]);

  const loadData = async (searchParams) => {
    const headerObject = {
      username: cookies.get("user_name") ? cookies.get("user_name") : null,
      user_id: cookies.get("user_id") ? cookies.get("user_id") : null,
      account_name: cookies.get("account_name") ? cookies.get("account_name") : null,
      account_id: cookies.get("account_id") ? cookies.get("account_id") : null,
      authHash: cookies.get("authHash") ? cookies.get("authHash") : null,
      sid: cookies.get("sid") ? cookies.get("sid") : null,
      baseurl: cookies.get("baseUrl") ? cookies.get("baseUrl") : null,
      id: cookies.get("id") ? cookies.get("id") : null,
    }
    if(!props?.transactionId)
    {
      return 
    }
    if (
      cookies.get("active_session") &&
      cookies.get("active_session") == "yes"
    ) {
      let object = {
        ...headerObject,
        ...(filters?.export_excel ? {} :
          {
            page: pageNumber,
            page_size: pageSize,
          }),
        search: "",
        ...searchParams,
        ...filters,
        transaction_id:props?.transactionId,
        with_out_status_not_audited: true
      };

      setLoading(true);

      const result = await transactionAuditAPI.transactionAudits({
        params: object,
        signal: controller?.signal,
      });
      setLoading(false);

      if (result.status && result?.data?.data) {


        if (filters?.export_excel) {
          setFilters({
            ...filters,
            export_excel: false
          })
          downloadExcel(result?.data?.data)
        }
        else {
          if (result?.data?.data?.data?.length > 0) {
            setRows(result?.data?.data?.data);
            setRowsTotal(result?.data?.data?.total_records);
            setPageCount(Math.ceil(result?.data?.data?.total_records / pageSize));
          }
          else {
            setRows([]);
            setRowsTotal(0);
            setPageCount(0);
          }

        }
      } else {

        if (result?.data?.data?.message) {
          toast.error(result?.data?.data?.message?.text ? result?.data?.data?.message?.text : result?.data?.data?.message)
        }
        else if (!result?.data?.error) {
          toast.error(t("NETWORK_MESSAGES.unknownError"));
          return;
        }
        if (result && result?.data && result?.data?.data?.message) {
          if (result?.data?.data?.message?.text == "unauthorized to complete") {
            navigate("/redirect");
          }
        }
      }
      setLoading(false);
    } else {
      navigate("/redirect");
    }
  };
  const updateFunction = (obj) => {

  }

  const createFunction = (obj) => {

  }


  const clearFilterFunction = () => {
    setFilters({
      name: "",
      code: "",
      type_id: null,
      wialon_id: null,
      export_excel: false
    })
  }
  const searchFunction = () => {
    setReload(!reload)
    setPageNumber(1)
  };

  const exportFunction = () => {

    setFilters({
      ...filters,
      export_excel: true
    })
    setReload(!reload)
  }
  const downloadExcel = async (fileName) => {
    const headerObject = {
      username: cookies.get("user_name") ? cookies.get("user_name") : null,
      user_id: cookies.get("user_id") ? cookies.get("user_id") : null,
      account_name: cookies.get("account_name") ? cookies.get("account_name") : null,
      account_id: cookies.get("account_id") ? cookies.get("account_id") : null,
      authHash: cookies.get("authHash") ? cookies.get("authHash") : null,
      sid: cookies.get("sid") ? cookies.get("sid") : null,
      baseurl: cookies.get("baseUrl") ? cookies.get("baseUrl") : null,
      id: cookies.get("id") ? cookies.get("id") : null,
    }
    const result = await transactionAuditAPI?.downloadExcel({
      params: {
        ...headerObject,
        fileName: fileName
      }
    })
    if (result.status && result?.data) {
      var _url = window.URL.createObjectURL(result?.data);
      window.open(_url, "_blank").focus(); // window.open + focus
    }
  }
  return (
    
      <Grid
        rows={rows}
        hasExport={rolesRedux?.export_transaction_audit?.value}
        exportFunction={exportFunction}
        columns={columns}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        loading={loading}
        handlePageChange={handlePageChange}
        rowsTotal={rowsTotal}
        checkScreenSize={screenwidth}
        pageCount={pageCount}
        openFilterColumn={openFilterColumn}

        setOpenFilterColumn={setOpenFilterColumn}
        setColumnFilterValue={setOpenFilterColumnValue}
        columFilterValue={openFilterColumnValue}

        clearFunction={clearFilterFunction}
        searchFunction={searchFunction}

        hasCreate={false}
        creatFunction={createFunction}
        notHaveAdvancSearch={true}
        customHeight={'300px'}
        filterChilds={null}
        customContianerSX={{
          padding:0
        }}
      />
  );
}
