import callAPI from '../ApiConfig'
import $ from "jquery";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const plateLetters = async (props) => {

    return await callAPI({
        route: "plate_letters/",
        method: "get",
        signal: props?.signal,
        params: props?.params,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }

    })

}
const addPlateLetter = async (props) => {


    return await callAPI({
        route: "plate_letters/",
        method: "post",
        signal: props?.signal,
        data: props?.data,
        params: props?.params,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })

}
const editPlateLetter = async (props) => {

    return await callAPI({
        route: "plate_letters/" + props?.id,
        method: "put",
        signal: props?.signal,
        data: props?.data,
        params: props?.params,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: 'application/json',
            ...props?.headers
        }
    })
}
const deletePlateLetter = async (props) => {


    return await callAPI({
        route: "plate_letters/" + props?.data?.id,
        method: "delete",
        signal: props?.signal,
        headers: {

        }
    })
}

const getPlateLetterList = async (props) => {

    return await callAPI({
        route: "plate_letters/",
        method: "get",
        signal: props?.signal,
        params: {
            ...props?.params,
            show_list: 1
        },
        headers: props?.headers

    })

}

const plateLetterAPI = {
    plateLetters: plateLetters,
    addPlateLetter: addPlateLetter,
    editPlateLetter: editPlateLetter,
    deletePlateLetter: deletePlateLetter,
    getPlateLetterList: getPlateLetterList
}
export default plateLetterAPI