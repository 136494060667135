import callAPI from '../ApiConfig'
import $ from "jquery";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const plateProvinces = async (props) => {

    return await callAPI({
        route: "plate_provinces/",
        method: "get",
        signal: props?.signal,
        params: props?.params,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }

    })

}
const addPlateProvince = async (props) => {


    return await callAPI({
        route: "plate_provinces/",
        method: "post",
        signal: props?.signal,
        data: props?.data,
        params: props?.params,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })

}
const editPlateProvince = async (props) => {

    return await callAPI({
        route: "plate_provinces/" + props?.id,
        method: "put",
        signal: props?.signal,
        data: props?.data,
        params: props?.params,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: 'application/json',
            ...props?.headers
        }
    })
}
const deletePlateProvince = async (props) => {


    return await callAPI({
        route: "plate_provinces/" + props?.data?.id,
        method: "delete",
        signal: props?.signal,
        headers: {

        }
    })
}

const getPlateProvinceList = async (props) => {

    return await callAPI({
        route: "plate_provinces/",
        method: "get",
        signal: props?.signal,
        params: {
            ...props?.params,
            show_list: 1
        },
        headers: props?.headers

    })

}

const plateProvinceAPI = {
    plateProvinces: plateProvinces,
    addPlateProvince: addPlateProvince,
    editPlateProvince: editPlateProvince,
    deletePlateProvince: deletePlateProvince,
    getPlateProvinceList: getPlateProvinceList
}
export default plateProvinceAPI