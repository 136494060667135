import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  translation_en: {
    GLOBAL: {},
    LOGIN: {
      login_title: 'Login'
    }
  },
  translation_ar: {
    GLOBAL: {
      ok: "حسنا",
      confirm: "تاكيد",
      arabic: "عربي",
      english: "انكليزي",
      return: "رجوع",
      installation_date_from: "من تاريخ التركيب",
      installation_date_to: "الى تاريخ التركيب",
      choose_installation_date_range: "اختر تاريخ التركيب",
      Save_and_update_from_wialon: "حفظ وتحديث من المنصة",
      total_filtered: "العدد الكلي بعد التصفية",
      selected: "المحددة",
      not_selected: "الغير محددة",
      selected_status: "حالة التحديد",
      total: "العدد الكلي",
      insert_or_update: "جديد او تعديل",
      choose_insert_or_update: " اختر نوع عملية استيراد البيانات",
      insert: " انشاء جديد",
      update: " تعديل بيانات موجوده مسبقا",
      unsubscribe: "الغاء تخويل",
      subscribe: "تخويل",
      items: " عنصر ",
      are_you_sure_select_all: "هل تريد اختيار جميع العناصر",
      select_all_items_in_all_pages: "اضغط نعم اذا كنت تريد اختيار جميع العناصر في كل الصفحات",
      are_you_sure_unselect_all: "هل تريد الغاء اختيار جميع العناصر",
      unselect_all_items_in_all_pages: "اضغط نعم اذا كنت تريد الغاء اختيار جميع العناصر في كل الصفحات",
      import_point_template: " اضغط لتحمل مثال للملف المطلوب",
      import_selected_data: "استيراد البيانات المحددة",
      import_data: "استيراد البيانات",
      import_form_upload_button: "اختر الملف",
      import_form_title: "استيراد نقاط من ملف",
      import_form_messages: "يجب ان يكون ملف xlsx يحتوي على عمودين للرمز والاسم فقط",
      import_form_message_not_selected_file: "لم يتم اختيار اي ملف",
      import_form_upload_button: "اختر الملف",
      export_data: "تصدير البيانات",
      refresh: "اعادة تحميل البيانات",
      show_hide_search: "اظهار البحث",
      create_report: "انشاء تقرير",
      show_hide_columns: "اظهار واخفاء الاعمدة",
      creation_date_from: "من تاريخ",
      creation_date_to: "الى تاريخ",
      choose_date: "اختر الوقت",
      all_time: "كل الوقت",
      from: "من",
      to: "الى",
      Save_and_close: "حفظ واغلاق",
      Select_all_and_close: "تحديد الكل واغلاق",
      auto_refresh: "تحديث تلقائي كل",
      second: "ثانية",
      are_you_sure: "هل انت متأكد",
      download_report: "اصدار تقرير",
      searching: "تحميل البيانات...",
      link_with_wialon: "الربط مع المنصة",
      unlink_with_wialon: "فك الربط مع المنصة",
      undefine: "غير معروف",
      cancel_button: 'الغاء',
      save_button: 'حفظ',
      GRID_NO_DATA: 'لا توجد بيانات',
      GRID_NO_RESULT_DATA: 'لا توجد بيانات',
      action_edit: 'تعديل',
      action_view: 'عرض',
      action_delete: "حذف",
      action_rating: 'تقييم',
      SEARCH_RESULT_OF_SEARCH: "النتيجة",
      SEARCH_SEARCH_BUTTON: 'بحث',
      SEARCH_CLEAR_BUTTON: "مسح",
      Save: 'حفظ',
      close: 'اغلاق',
      cancel: "الغاء",
      SEARCH_RESULT_OF_selected: "المحددة",
      alertPopup: 'تحديث بيانات المستخدم',
      updateUserInfo: 'تحديث',
      endSessionMessage: 'تم انتهاء الجلسة الخاصة بك سيتم تحويلك لصفحة التسجل خلال',
      endSessionMessageUnits: 'ثانية',
      sorryMessage: "عذرا لم نتمكن من تحويلك الى صفحة تسجيل الدخول، رجاءا قم  بتسجيل الدخول مره اخرى من النظام الرئيسي",
      pageNotFoundMessage: "عذرا الصفحة غير موجوده",
      clear: 'مسح',
      empty: 'لايوجد وظائف',
      mandatory_field: 'حقل الزامي',
      NotAuthorizedMessage: "لاتمتلك صلاحية الدخول لهذه الصفحة",
      yes: "نعم",
      no: "كلا"
    },
    LOGIN: {
      login_title: 'تسجيل دخول',
      company_name: 'نظام مراقب الصهاريج',
      company_title: 'لمراقبة الصهاريج',
      welcome_message: 'مرحبا بك في نظام مراقبة الصهاريج',
      Introductory_message: 'هذا نظام آمن وستحتاج إلى تقديم تفاصيل تسجيل الدخول الخاصة بك للوصول إلى الموقع.',
      user_name: 'اسم المستخدم',
      user_password: 'كلمة المرور',
      checkbox_remember_me: 'تذكرني',
      login: 'تسجيل دخول',
      forget_password: 'هل نسية كلمة المرور؟',
      footer_message: 'مقدم من شركة السلام',
      reset_password_title: 'أكتب رقم الهاتف الخاص بك لاستعادة كلمة المرور',
      phone_number: 'رقم الهاتف',
      Submit: 'أرسال',
      recived_sms_code_title: 'أدخل الرمز المرسل اليك برسالة قصيرة',
      not_recivied_message: 'لم يصلك الرمز ؟',
      reSend_message: 'أعادة ارسال',
      new_password_form_title: 'يرجى كتابة كلمة المرور الجديدة الان',
      confirm_user_password: 'تأكيد كلمة المرور',
      save_changes_passwords: 'حفظ التغييرات',
    },
    ERROR: {
      login_username_error: 'اسم المستخدم مطلوب',
      login_userpassword_error: 'يجب ادخال كلمة المرور',
      userName_or_password_error: 'اسم المستخدم او كلمة المرور غير صحيحة'

    },
    SIDEMENU: {
      edit_and_update_main_unit_plate: "معالجة اللوحات",
      units_settings: "اعدادات الوحدات",
      units_governmental: "الوحدات الحكومية",
      units_private: "الوحدات الاهلية المركبة",
      main_units_installation_check: "تدقيق حالة المركبات",
      main_accounts: "الحسابات الرئيسية",
      settings: "اعدادات",
      tags: "الملصقات المرمزة",
      unit_sections: "شعب الوحدات",
      geofence_groups: "مجاميع النقاط",
      unit_types: "اقسام الوحدات",
      main_units: "المركبات المسجلة",
      mobile_users: "مستخدمي التلفون",
      dashboard: 'لوحة التحكم',
      Requests: 'المستندات',
      profile: 'الملف الشخصي',
      logout: 'تسجيل خروج',
      units: 'الوحدات',
      geofences: 'النقاط',
      users: 'المستخدمين',
      routes: 'الطرق',
      transactions: 'المستندات',
      activities: 'الفعاليات',
      units_transactions: 'نقلات الوحدات',
      reports: 'التقارير',
      report_by_transaction_number: 'حسب رقم المستند',
      report_stops_info: 'تقرير النقلات والتوقفات',
      report_by_is_monitored: 'حسب حالة المراقبة',
      report_by_source_geofence: 'حسب نقطة التحميل',
      report_daily_violation: 'استمارة المخالفات اليومية',
      report_by_destination_geofence: 'حسب نقطة التفريغ',
      report_by_product_type: 'حسب نقطة تفريغ ونوع منتج',
      report_by_source_and_product_type: 'تقرير الكميات المجهزة من مستودع',
      report_by_destination_and_product_type: 'تقرير الكميات المجهزة الى محطة',
      report_by_audited_per_user: 'تقرير احصائيات التدقيق',
      report_for_main_units: 'تقرير احصائيات المركبات المسجلة',
      report_for_missing_destinations: 'تقرير نقاط التفريغ الغير مسجلة',
      report_for_missing_routes: 'تقرير الطرق الغير مسجلة',
      report_audited_units_monitored_by_user: 'تقرير المركبات المتابعة'
    },
    MAIN_ACCOUNTS: {
      title: "حسابات رئيسية",
      name: "الاسم",
      code: "رمز المنصة",
      enabled: "فعال",
      actions: "الاجراءات",
      update_title: "تعديل حساب رئيسي",
      add_title: "اضافة حساب رئيسي"

    },
    TAGS: {
      connected: "مرتبطة",
      is_connected: "مرتبطة بوحدة مسجلة",
      not_connected: "غير مرتبطة",
      add: "اضافة ملصق",
      title: "الملصقات المرمزة",
      import_form_title: "استيراد الملصقات المرمزة من ملف",
      name: "TID",
      code: "EPC",
      enabled: "فعال",
      actions: "الاجراءات",
      update_title: "تعديل ملصق مرمز",
      add_title: "اضافة ملصق مرمز",
      disabled: "غير فعال",
      all: "الكل",

    },
    UNIT_SECTIONS: {
      add: "اضافة شعبة",
      title: "الشعب",
      import_form_title: "استيراد الشعب من ملف",
      name: "الاسم",
      code: "الرمز",
      enabled: "فعال",
      actions: "الاجراءات",
      update_title: "تعديل شعبة",
      add_title: "اضافة شعبة",
      disabled: "غير فعال",
      all: "الكل",

    },
    GEOFENCE_GROUPS: {
      empty_geofences_list: "يجب اختيار نقطة واحدة على الاقل",
      add: "اضافة مجموعة لنقاط",
      title: "مجاميع النقاط",
      name: "الاسم",
      code: "الرمز",
      enabled: "فعال",
      actions: "الاجراءات",
      update_title: "تعديل مجموعة لنقاط",
      add_title: "اضافة مجموعة لنقاط",
      disabled: "غير فعال",
      all: "الكل",

    },
    UNIT_TYPES: {
      add: "اضافة قسم",
      title: "الاقسام",
      import_form_title: "استيراد القسم من ملف",
      name: "الاسم",
      code: "الرمز",
      enabled: "فعال",
      actions: "الاجراءات",
      update_title: "تعديل قسم",
      add_title: "اضافة قسم",
      disabled: "غير فعال",
      all: "الكل",

    },
    DASHBOARD: {
      total_governmental: 'اجمالي حكومي',
      total_private: 'اجمالي اهلي',
      total_no_unit: 'اجمالي بدون مركبة',
      total_audited: 'اجمالي المدقق',
      total_not_audited: 'اجمالي الغير مدقق',
      completed_with_violation: 'المستندات المكتملة مع مخالفة',
      completed_with_out_violation: 'المستندات المكتملة بدون مخالفة',
      total_pending: 'المستندات التي في الطريق',
      total_completed: 'اجمالي المستندات المكتملة',
      total_not_completed: 'اجمالي المستندات الغير مكتمل',
      total_monitored: 'اجمالي المستندات المراقبة',
      total_not_monitored: 'اجمالي المستندات الغير مراقبة',
      all_transactions: 'جميع المستندات',
      total_in_road: 'في الطريق',
      total_in_road_valid: 'ملتزم',
      total_in_road_not_valid: 'خروج،توقف,لم يفرغ',
      total_completed_valid: 'ملتزم',
      total_completed_not_valid: 'خروج،توقف,لم يفرغ',
      total_exceeded: 'تعدى الوقت',
      title: 'لوحة التحكم',

    },
    ADVANCESEARCH: {
      title: 'البحث عن عملاء',
      customer_name: 'أسم العميل',
      customer_phone_number: 'رقم الهاتف',
      Name_ofCumpany: "تاريخ التسجيل",
      fromDate: 'من ',
      toDate: 'الى',
      no_cutomerTypeData: 'لا توجد بيانات',
      SelectWorkType: 'نوع العميل',
      salesEmployee: 'موظف المبيعات',
      selectedReason: 'الغرض',
      no_resones: 'لا توجد بيانات',
      advancesearch: 'البحث المتقدم',
      applaySearch: 'تطبيق البحث',
      sideOfWork: 'جهة العمل',
      no_workside: 'لا توجد بيانات',
      companyname: 'اسم الشركة',


    },
    REQUESTS: {
      title: 'المستندات',
      ID: '#',
      accountName: 'اسم الحساب',
      accountUserName: 'اسم المستخدم',
      dateforRequest: 'رقم وتاريخ المستند',
      requestNumber: 'رقم المستند',
      requestType: 'نوع المستند',
      deviceNumber: 'رقم الجهاز',
      dateAndFavoriteTIme: 'التاريخ والوقت المفضل',
      status: 'الحالة',
      actions: 'الوظائف',
      archived_request: "مؤرشفة",
      approved_request: "تمة الموافقة",
      rejected_request: "رفضت",
      closed_request: "مغلقة",
      inprogress_request: "قيد المعالجة",
      new_request: 'جديد',
      requestDate: 'من تاريخ المستند',
      endrequestDate: 'الى تاريخ مستند',
      unitName: 'اسم الوحدة',
      addTitle: 'أضافة مستند',
      updateTitle: 'تعديل بيانات المستند',
      add_phone: 'رقم الهاتف',
      add_location: 'مكان المستند',
      add_request_type: 'نوع المستند',
      add_units: 'الوحدات',
      add_prefered_date: 'التاريخ المفضل',
      add_prefered_time: 'الوقت المفضل',
      add_notes: 'الملاحضات',
      add_customer_name: 'اسم الزبون',
      add_username: 'اسم المستخدم',
      add_request_date: 'تاريخ المستند',
      add_status: 'الحالة',
      add_inprogress_dateTime: 'وقت وتاريخ بدء العمل',
      add_closing_dateTIme: 'وقت وتاريخ الاغلاق',
      add_unit_reasone: 'السبب',
      add_unit_name: 'اسم الوحدة',
      add_unit_id: '#',
      no_menu_itme: 'لا توجد عناصر تحكم',
      viewRequest: 'عرض تفاصيل المستند',
      units_list: 'قائمة الوحدات',
      ratedRequest: 'نوع التقييم',
      rating: 'التقييم',
      addRating: 'اضافة تقييم',
      add_ratingsNot: 'ملاحظات',
      add_rating_suggestions: 'اي اقتراح',
      maessage_after_rating_1: 'شكرا لوقتك. نحن ناسف لتجربتك الغير جيدة. سوف يتم الاتصال بحضرتك لبحث سبب المشكلة والعمل على حلها في المستقبل',
      maessage_after_rating_2: 'شكرا لوقتك. نحن ناسف لتجربتك الغير جيدة. سوف يتم الاتصال بحضرتك لبحث سبب المشكلة والعمل على حلها في المستقبل',
      maessage_after_rating_3: 'شكرا لوقتك. على ما يبدوا ان تجربتك لم تكن ممتازة. سوف يتم مراجعة تقييمك والعمل على اصلاح الخلل',
      maessage_after_rating_4: 'شكرا لوقتك. نحن نعمل على تقديم أفضل الخدمات. نأمل بان نقدم لك خدمة أفضل في المستقبل',
      maessage_after_rating_5: 'شكرا لوقتك. نحن سعداء بخدمتك',
      unitName_details: 'تفاصيل الوحدات',
      reasonTitle: "اضفافة ملاحظة",
      notesReason: 'ملاحظة',
      noteReason: 'ملاحظة',
      add_unit_notes: 'السبب'
    },
    NETWORK_MESSAGES: {
      infoAddedSuccessfully: 'تم حفظ المعلومات بنجاح',
      infoUpdatedSuccessfully: 'تم تحديث المعلومات بنجاح',
      added_successfully: "تمت الاضافة بنجاح",
      unknownError: 'خطأ غير معروف',
      infoDeletedSuccessfully: 'تم الحذف بنجاح',
      messageError: 'حصل خطا في تحميل البيانات'
    },
    TOOLTIP: {
      EXPORT: 'تصدير',
      IMPORT: 'استيراد',
      CREATE: 'انشاء',
      MAP: 'خريطة',
      TELEGRAM: 'تليكرام',
    },
    PROFILE: {
      email: 'البريد الالكتروني',
      USER_TITLE: 'معلومات المستخدم',
      FULL_NAME: "أسم المستخدم كامل",
      USERNAME: 'اسم المستخدم',
      PHONE: "رقم الهاتف",
      UPDATE_BUTTON: "تحديث البيانات",

    },
    UNITS: {
      wialon_values: "تفاصيل المنصة",
      copy_full_name: "نسخ الرقم كامل",
      show_activities: "سجل التعديلات",
      details: "التفاصيل",
      update_wialon: "تحديث في المنصة",
      dont_update_wialon: "بدون تحديث المنصة",
      plate_class: "صنف اللوحة",
      insert_new_values: "ادخل البيانات الجديدة",
      update_plate_number: "تحديث الى رقم جديد",
      edit_plate_number: "تعديل الرقم ",
      search_plate_number: "بحث عن رقم مركبة",
      last_payment_date_formatted: "تاريخ اخر دفعة",
      first_payment_date: "تاريخ اول دفعه",
      full_update_title: "تعديل متقدم",
      view_title: "تفاصيل الوحدة",
      national_id_number: "رقم الهوية أو البطاقة الموحدة",
      car_registration_number: "رقم السنوية",
      car_registration_date: "تاريخ اصدار السنوية",
      car_registration_to_date: "تاريخ نفاذ السنوية",
      truck_vin_number: "رقم شاصي أو تسلسل المقطورة",
      takeel_number: "الرقم التسلسلي لشهادة التكييل",
      takeel_by: "جهة الإصدار التكييل",
      created_date: "تاريخ الإصدار",
      expire_date: "تاريخ الانتهاء",
      update_note: " ملاحظات التحديث ",
      numbering: "التسلسل",
      installation_date_formatted: "تاريخ التنصيب",
      vin_number: "رقم الشاصي",
      has_receipt_number: "مع وصل",
      doesnt_have_receipt_number: "بدون وصل",
      doesnt_have_duplicate: " غير مكررة",
      has_duplicate: "مركبات مكررة",
      governmental_units: "الوحدات الحكومية",
      private_units: "الوحدات الاهلية المركبة",
      refresh_type: "نوع التحديث",
      select_refresh_type: "اختر نوع التحديث",
      refresh_from_wialon: "تحديث عميق",
      refresh_from_db: "تحديث عادي",
      is_governmental: "حكومي/اهلي",
      governmental: "حكومي",
      private: "اهلي",
      unknown: "غير معروفة",
      transactions_detail: "تفاصيل النقلات",
      inside_baghdad: "نقلات الداخلية",
      outside_baghdad: "نقلات خارجية",
      driver_name: "اسم السائق",
      unit_section_name: "الشعبة",
      unit_type_name: "القسم او النوع",
      vin: "الشاصي",
      barcode: "الملصق",
      number_and_letter: "رقم وحرف",
      delete: "حذف",
      has_epc: "تحتوى باركود",
      dosent_have_epc: "لاتحتوى",
      has_tid: "تحتوى ملصق ",
      dosent_have_tid: "لاتحتوي",
      name_and_type: "موديل",
      show_logs: "سجل الفحص",
      log_map: "موقع الفحص",
      logs: "سجل الفحص",
      formatted_created_at: "تاريخ الفحص",
      username: "اسم الفاحص",
      tid: "رمز الملصق",
      epc: "رمز الباركود",
      import_form_title: "استيراد المركبات المسجلة من ملف",
      unlink: "فك ربط",
      select_wialon_unit: "اختر وحده لربطها",
      exportExcel: "تصدير اكسل",
      exportPDF: "تصدير PDF",
      report_for_main_units: "احصائيات المركبات المسجلة",
      total_units: "اجمالي الوحدات",
      total_installed: "المركبة",
      total_not_installed: "الغير مركبة",
      total_subscribed: "مخولة",
      total_not_subscribed: "غير مخولة",
      clear_all: "مسح جميع البيانات",
      enabled: "فعالة",
      disabled: "معطلة (محذوفة)",
      driver_name: "اسم السائق",
      company_name: "الشركة",
      note: "ملاحظات",
      not_installed: "لم يتم التركيب",
      not_installed_duplicate: "لم تركب وجود اكثر من مركبة",
      installed: "تم التركيب",
      receipt_number: "رقم الوصل",
      installation_date: "تاريخ التركيب",
      is_installed: "حالة التركيب",
      carrier: "الناقل",
      carrier_name: "اسم الناقل",
      carrier_governmental: "حكومي",
      carrier_private: "اهلي",
      tag_name: "رمز الملصق",
      unit_section: "الشعبة",
      unit_type: "القسم",
      is_subscribed: "حالة التخويل",
      tag: "ملصق مرمز",
      main_account: "حساب رئيسي",
      original_customer: "الزبون الرئيسي",
      receipt_date: "تاريخ الوصل",
      subscribed: "مخول",
      not_subscribed: "غير مخول",
      add_main_unit: "اضافة مركبة مسجلة",
      title_main: "المركبات المسجلة",
      account_name: "العائدية او الحساب",
      search_by_name_or_id_or_account: "بحث عن الاسم او المعرف او اسم الحساب",
      is_not_monitored: "غير متابعة",
      is_monitored: "متابعة",
      all: "الكل",
      monitored_by: "متابع من قبل",
      transactions_total_greater_than_from: "عدد نقلات اكثر من",
      transactions_total_less_than_from: "عدد نقلات اقل من",
      title_units_transactions: "المركبات وعدد نقلاتها",
      transactions_total: "عدد النقلات",
      name: "الاسم",
      plate_number: "رقم اللوحة",
      plate_letter: "الحرف",
      plate_province: "المحافظة",
      plate_type: "نوع اللوحة",
      cls: "cls",
      mu: "mu",
      uacl: "uacl",
      wialon_name: "الاسم في المنصة",
      wialon_id: "معرف المنصة",
      actions: "الوظائف",
      add: "اضافة",
      title: "المركبات ",
      update_title: "تعديل وحدة",
      add_title: "اضافة وحدة",
      refresh_data_from_wialon: "تحديث البيانات من المنصة",
      refresh_not_updated_data_from_wialon: "تحديث البيانات الغير محدثة فقط",
    },
    GEOFENCES: {
      update_code: "تعديل الرمز",
      update_name: "تعديل الاسم",
      last_updated: "اخر تحديث",
      you_have_to_select_point_to_link: "يجب اختيار معرف المنصة",
      no_points_with_same_code_found: "لم يتم العثور على نقاط بهذا الرمز",
      wialon_link_will_be_removed: "سوف يتم فك الربط",
      unlink: "فك ربط",
      link: "ربط",
      wialon_link_will_be_updated: "سوف يتم تعديل الربط للنقطة",
      update_wilaon_link: "تعديل الربط",
      linked_to_wialon: 'مرتبط',
      not_linked_to_wialon: 'غير مرتبط',
      all: "الكل",
      name: "الاسم",
      code: "الرمز",
      type: "النوع",
      wialon_id: "معرف المنصة",
      actions: "الوظائف",
      add: "اضافة",
      title: "النقاط ",
      update_title: "تعديل نقطة",
      add_title: "اضافة نقطة",
      port_code: "الترميز ",
      port_type: "الحالة",
      port_return: "العائدية",
      port_category: "النوع",
      branch: "الفرع",
      disable: "ابطال",
      enable: "تفعيل",
      goefence_will_be_disabled: "سوف يتم ابطال النقطة",
      goefence_will_be_enabled: "سوف يتم تفعيل النقطة"

    },
    ROUTES: {
      source_geofence: "نقطة التحميل",
      destination_geofence: "نقطة التفريغ",
      refresh_route_from_wialon: "تحديث بيانات الطرق",
      last_updated: "اخر تحديث",
      no_routes_with_same_code_found: "لم يتم العثور على طرق بهذا الرمز",
      you_have_to_select_rout_to_link: "يجب اختيار معرف المنصة",
      wialon_link_will_be_removed: "سوف يتم فك الربط",
      unlink: "فك ربط",
      link: "ربط",
      note: "ملاحظة",
      linked_to_wialon: 'مرتبط',
      not_linked_to_wialon: 'غير مرتبط',
      all: "الكل",
      name: "الاسم",
      code: "الرمز",
      wialon_id: "معرف المنصة",
      actions: "الوظائف",
      add: "اضافة",
      title: "الطرق",
      update_title: "تعديل طريق",
      add_title: "اضافة طريق",
      number: "عدد الطرق",
      source_geofence_name: "من",
      destination_geofence_name: "الى",
      routes_count: "عدد الطرق الفعالة",
      action_edit_wialon_connection: "تعديل الربط",
      disable: "ابطال",
      enable: "تفعيل",
      route_will_be_disabled: "سوف يتم ابطال الطريق",
      route_will_be_enabled: "سوف يتم تفعيل الطريق"


    },
    TRANSACTIONS: {
      source_port_categories: "نوع نقطة التحميل",
      destination_port_categories: "نوع نقطة التفريغ",
      source_branch: "تحميل فقط",
      destination_branch: "تفريغ فقط",
      unit_transaction_type: "نوع النقلة",
      fast: "سريع",
      normal: "طبيعي",
      unit_registration_number: "رقم المركبة",
      total_stops: "عدد التوقفات",
      duration_str: "مدة التوقف",
      trip_duration: "مدة الرحلة",
      begining_trip_time_string: "وقت بدء الرحلة",
      end_trip_time_string: "وقت انتهاء الرحلة",
      park_duration_str: "مدة التوقف",
      begining_time_string: "من",
      end_time_string: "الى",
      report_date: "تاريخ التقرير",
      carrier_governmental: "حكومي",
      carrier_private: "اهلي",
      account_name: "اسم الفرع",
      total_transactions_per_account: "اجمالي مستندات الفرع",
      users: "المستخدمين",
      accounts: "الفرع او الحساب",
      exportPDFNote: "تصدير مع الملاحظات",
      total_transactions: "العدد الكلي للمستندات",
      unit_monitored_by_name_formatted: "المركبة متابعة من قبل",
      unit_is_monitored: "المركبة متابعة",
      unit_is_not_monitored: "المركبة غير متابعة",
      routes_codes: "رمز (رموز) الطرق",
      map_location: "موقع انطلاق المركبة",
      far_from_source: "انطلقت من مكان ابعد من نقطة التحميل",
      not_far_from_source: "من داخل نقطة التحميل",
      branches_general: "الفرع (الفروع)",
      create_route: "اضافة مسار للمستند",
      mandatory_field_if_status_changed: "يجب اضافة ملاحظة عند تغيير الحالة",
      audited_by_name: "دقق من قبل",
      audit_created_by_name: "اسم المدخل",
      audit_created_at: "تاريخ الادخال",
      transaction_status_name: "حالة التدقيق",
      transaction_status: "حالة تدقيق المستند",
      audit: "تدقيق المستند",
      not_valid: "خروج، توقف او لم يفرغ",
      valid: "ملتزم بكل شئ",
      transaction_update_exceeded_time: "لايمكن التعديل: لقد تجاوزت الوقت المسموح للتعديل",
      is_arrived: "وصل للوجهة",
      isnt_arrived: "لم يصل",
      search_destination_geofence_name: "نقطة التفريغ  ( بدون ترميز )",
      search_in_notes: "البحث في الملاحظات",
      destination_warehouse_grid_header_1: "نقطة التفريغ",
      destination_warehouse_grid_header_2: "بدون ترميز",
      destination_warehouse_grid: "نقطة التفريغ بدون ترميز",
      no_routes: "عدم وجود طريق",
      no_valid_routes: "لاتوجد طرق مسجلة او مرتبطه",
      routes_count: "عدد الطرق المتوفرة",
      start_export_report: "بدء عملية انشاء تقرير؟",
      source_branches: "فروع التحميل",
      destination_branches: "فروع التفريغ",
      transaction_statuses: "حالات التدقيق",
      arrived_at: "وقت الوصول",
      all: "الكل",
      product_type: "نوع المنتج",
      liters: "الكمية",
      has_stop_violation: "لديه توقفات",
      doesnt_have_stop_violation: "ليس لديه توقفات",
      stop_violation_counter: "عدد التوقفات",
      exceeded_allowed_time: "تعدى الوقت المحدد",
      not_exceeded_allowed_time: "ضمن الوقت",
      is_monitored: "مراقبة",
      monitored: "مراقبة",
      not_monitored: "غير مراقبة",
      has_violation: "الخروج عن المسار",
      doesnt_has_violation: "الالتزام بالمسار",
      doesnt_has_violation_header: "الالتزام بالمسار",
      is_complete: "توقف تام",
      is_complete_grid_title: "توقف في منفذ التفريغ",
      payload_unloaded_incomplete: "توقف غير كافي",
      payload_not_unloaded: " بدون توقف",
      trip_range_time_difference: "وقت نطاق الرحلة",
      trip_range_time_difference_tooltip: "الوقت المستغرق من وقت اصدار المستند الى وقت الوصول الى منفذ التفريغ",
      trip_duration_time_difference: "مدة الرحلة",
      trip_duration_time_difference_tooltip: "الوقت المستغرق من نقطة التحميل الى نقطة التفريغ",
      not_complete: "غير مكتملة",
      note: "الملاحظات",
      unit: "المركبة",
      view_transaction: "تفاصيل المستند",
      source_geofence: "نقطة التحميل",
      destination_geofence: "نقطة التفريغ",
      route: "الطريق المحدد",
      add: "اضافة",
      actions: "الوظائف",
      title: "المستندات",
      update_title: "تعديل مستند",
      add_title: "اضافة مستند",
      routes_allowed: "الطرق المسموحة",
      no_routes_found: "لم يتم العثور على طرق",
      date_time: "وقت وتاريخ اصدار المستند",
      date_time1: "وقت وتاريخ",
      date_time2: "اصدار المستند",
      inserted_at: "وقت وتاريخ استلام المستند",
      inserted_at1: "وقت وتاريخ",
      inserted_at2: "استلام المستند",
      time_difference: "الفارق الزمني بين اصدار واستلام المستند",
      time_difference1: "الفارق الزمني بين",
      time_difference2: "اصدار واستلام المستند",
      out_from_source_at: "وقت وتاريخ اول خروج من نقطة التحميل",
      out_from_source_at1: "وقت وتاريخ الخروج",
      out_from_source_at2: "من نقطة التحميل",
      created_by_name: "مدخل المستند",
      transaction_number: "رقم المستند",
      creation_date_from: "من تاريخ",
      creation_date_to: "الى تاريخ",
      id: "التسلسل",
      unit_name: "اسم الوحدة",
      formatted_datetime: "وقت وتاريخ اصدار المستند",
      source_geofence_name: "نقطة التحميل",
      destination_geofence_name: "نقطة التفريغ",
      source_geofence_code: "رمز نقطة التحميل",
      destination_geofence_code: "رمز نقطة التفريغ",
      routes_names: "الطرق",
      note: "الملاحظات",
      is_monitored_title: "حالة المراقبة",
      exceeded_allowed_time_title: "حالة تعدي الوقت",
      is_complete_title: "حالة اكتمال المستند",
      has_violation_title: "حالة المخالفات",
      unit_registration_type: "نوع لوحة التسجيل للوحدة",
      unit_province: "محافظة المركبة",
      transaction_type: "نوع المستند",
      driver_name: "اسم السائق",
      source_warehouse: "اسم نقطة التحميل من المستودع",
      source_province: "اسم محافظة نقطة التحميل",
      destination_warehouse: " اسم نقطة التفريغ من المستودع",
      destination_province: "اسم محافظة نقطة التفريغ",
      carrier: "الناقل",
      carrier_name: "اسم الناقل",
      transaction_type_2: "نوع المستند الثاني",
      view_title: "تفاصيل المستند",
      update_destination_geofence: "اضافة الوجهة",
      stops: "التوقفات",
      stop_type: "نوع التوقف",
      stop_created_at: "وقت التوقف",
      map_stop: "موقع التوقف في الخريطة",
      route_code: "الرمز",
      route_name: "الاسم",
      routes: "الطرق",
      exportExcel: "تصدير اكسل",
      exportPDF: "تصدير PDF",
      exportByTransactionNumber: "تصدير تقرير حسب رقم المستند",
      exportByIsMonitored: "تصدير تقرير حسب حالة المراقبة",
      exportBySourceGeofence: "تصدير تقرير حسب نقطة التحميل",
      exportByDestinationGeofence: "تصدير تقرير حسب نقطة التفريغ",
      exportFullExcel: "تصدير البيانات",
      reportByTransactionNumber: "تقرير حسب رقم المستند",
      reportByIsMonitored: "تقرير حسب حالة المراقبة",
      reportBySourceGeofence: "تقرير حسب نقطة التحميل",
      reportByDestinationGeofence: "تقرير حسب نقطة التفريغ",
      reportByProductType: "تقرير حسب نقطة تفريغ ونوع منتج",
      reportBySourceAndProductType: "تقرير الكميات المجهزة من مستودع",
      reportByDestinationAndProductType: "تقرير الكميات المجهزة الى محطة",
      reportByAuditedPerUser: "تقرير احصائيات التدقيق",
      reportAuditedForMonitoredUnits: "تقرير احصائيات تدقيق المستندات للمركبات المتابعة",
      reportMissingDestinations: "تقرير نقاط التفريغ الغير مسجلة",
      reportMissingRoutes: "تقرير الطرق الغير مسجلة",
      total_liters: "اجمالي الكمية",
      total_transactions: "اجمالي عدد المستندات",
      status_1_name_total: "غير مدقق",
      status_2_name_total: "قيد التدقيق",
      status_3_name_total: "مخالف",
      status_4_name_total: "غير مخالف"

    },
    USERS: {
      geofence_group_id: "معرف المجموعة",
      geofence_group_name: "اسم المجموعة",
      geofence_groups: "مجاميع النقاط",
      wialon_group_name: "اسم المجموعة",
      destination_name_search: "بحث عن اسم الوجهة",
      destination_geofence_id: "معرف الوجهة",
      destination_geofence_name: "اسم الوجهة",
      monitor_destination_geofences: "مراقبة الوجهات",
      is_subscribed: "حالة التخويل",
      title_mobile: "مستخدمين الموبايل",
      app_version: "نسخة التطبيق",
      account_name: "اسم الحساب",
      unit_name: "اسم المركبة",
      unit_id: "معرف المركبة",
      monitor_units: "مراقبة الوحدات",
      formatted_last_login_date: "اخر تسجيل دخول",
      account_name: "اسم الحساب",
      wialon_user: "اسم النظام الرئيسي",
      name: "الاسم",
      username: "اسم المستخدم",
      enabled: "فعال",
      disabled: "غير فعال",
      add: "اضافة",
      title: "قائمة المستخدمين",
      phone: "الهاتف",
      actions: "الوظائف",
      update_title: "تعديل المستخدم",
      add_title: "اضافة مستخدم",
      all: "الكل",
      password: "كلمة المرور",
      role_selected: "المحددة",
      role_unselected: "الغير محددة",
      user_roles: "صلاحيات المستخدم",
      role_id: "#",
      role_name: "الاسم",
      assign_roles: "تعيين صلاحيات",
      role_name_search: "بحث عن اسم"
    },
    ACTIVITIES: {
      activities: 'الفعاليات',
      name: "الاسم",
      note: "ملاحظة",
      created_at: "تاريخ التعديل",
      type: "نوع التعديل",
      created_by_id: "تم التعديل بوساطة",
      date: "تاريخ التعديل"
    }
  },

};
export const TranslateSlice = createSlice({
  name: "translateData",
  initialState: initialState,
  reducers: {
    setTranslateEnglish: (state, action) => {
      state.translation_en = action.payload
    },
    setTranslateArabic: (state, action) => {
      state.translation_ar = action.payload
    },
  },
});

export const {
  setTranslateEnglish,
  setTranslateArabic,
} = TranslateSlice.actions;

// export const sideMenuMode = state => state.settingsData.sideMenuMode;

export default TranslateSlice.reducer;
