import React, { useState } from 'react'
import { Autocomplete, Box, TextField } from '@mui/material'
import { createFilterOptions } from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux'
import * as RiIcons from 'react-icons/ri'
import { ContainerOfSelectField } from './ThemDesign';
function CustomeSelectField({
    label, error, message, value, onChange, onClearClick, readOnly,
    list, haswidth, customGetOptionLabel, multiple, margin, focused, renderOption
    , onInputChange, inputValue, loading, loadingText, name, customWidth
}) {
    const maintheme = useSelector(state => state.themeData.maintheme);
    const [localInputValue, setLocalInputValue] = useState("")

    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        stringify: (option) => option[name ? name : "name"] ? option[name ? name : "name"] : "",
    });


    return (
        <ContainerOfSelectField
            mainTheme={maintheme}
            customWidth={customWidth ? customWidth : "100%"}
            isForm={true}
            haswidth={haswidth ? true : false}
            hasError={error}
        >
            <Box
                sx={{
                    position: "relative",
                    // margin: "5px 0",
                    margin: margin ? margin : 'none',
                    width: customWidth ? customWidth : "100%",
                    backgroundColor: "#fff",
                }}
                className="orderdata"
            >
                <Autocomplete
                    // disablePortal
                    id="combo-box-demo"
                    noOptionsText="لايوجد خيارات متاحة"
                    options={list ? list : []}
                    value={value}
                    filterOptions={filterOptions}
                    onInputChange={(event, newInputValue) => {

                        onInputChange ? onInputChange(newInputValue) : setLocalInputValue(newInputValue);
                    }}
                    inputValue={inputValue ? inputValue : localInputValue}
                    onChange={(e, newValue) => {
                        onChange && onChange(e, newValue)
                    }}
                    getOptionLabel={customGetOptionLabel ? customGetOptionLabel : ((option) => {

                        return option?.name
                    }) || ''}
                    popupIcon={<RiIcons.RiArrowDropDownLine />}
                    readOnly={readOnly}
                    InputProps={{
                        readOnly: readOnly,
                    }}
                    loading={loading}
                    loadingText={loadingText}
                    multiple={multiple}
                    renderOption={renderOption}
                    clearIcon={<CloseIcon sx={{ fontSize: '20px', color: maintheme?.iconColor }} onClick={() => onClearClick && onClearClick()} />}
                    renderInput={(params) => <TextField {...params} label={label} variant="filled" />}
                    className={`${error ? 'Mui-focused errors' : ''} ${focused ? 'Mui-focused' : ''}`}
                />
            </Box>
            {
                error && message?.length ?
                    <Box sx={{
                        height: 'fit-content',
                        padding: '5px',
                        display: 'flex',
                        flexDirection: 'column',
                        flexWrap: 'wrap',
                        width: '95%',
                    }}>
                        {
                            message && message?.length > 0 ? message?.map((messg) => (
                                <span style={{ fontFamily: 'Cairo-Bold', fontSize: '14px', color: 'red', height: 'auto' }}>{messg}</span>
                            )) : null
                        }
                    </Box> : null}
        </ContainerOfSelectField>
    )
}

export default CustomeSelectField