import { Autocomplete, Box, FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import React, { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import SearchInput from '../../Components/SearchInput';
import unitAPI from '../../Network/Unit';
import geofenceAPI from '../../Network/Geofence';
import routeAPI from '../../Network/Route';
import Cookies from "universal-cookie";
import moment from 'moment/moment';
import CustomDateRange from '../../Components/CustomDateRange';
const cookies = new Cookies();

const Search = ({
  filters,
  setFilters,
  loadingBranches,
  branches,
  portCategories,
  loadingPortCategories,
  loadingTransactionStatuses,
  loadingUsers,
  users,
  transactionStatuses,
  transactionType2List,
  loadingTransactionType2List,
  noUnits,
  showUnitTransactionType,
  loadingTransactionProductTypes,
  transactionProductTypes,
}) => {
  const [t] = useTranslation("common");
  const timerUnits = useRef(null)
  const timerSourceGeofences = useRef(null)
  const timerDestinationGeofences = useRef(null)
  const timerRoutes = useRef(null)

  const [units, setUnits] = useState([])
  const [sourceGeofences, setSourceGeofences] = useState([])
  const [destinationGeofences, setDestinationGeofences] = useState([])
  const [routes, setRoutes] = useState([])

  const [loadingUnits, setLoadingUnits] = useState(false)
  const [loadingSourceGeofences, setLoadingSourceGeofences] = useState(false)
  const [loadingDestinationGeofences, setLoadingDestinationGeofences] = useState(false)
  const [loadingRoutes, setLoadingRoutes] = useState(false)


  const [searchTermUnit, setSearchTermUnit] = useState("");
  const [searchTermSourceGeofence, setSearchTermSourceGeofence] = useState("");
  const [searchTermDestinationGeofence, setSearchTermDestinationGeofence] = useState("");
  const [searchTermRoute, setSearchTermRoute] = useState("");

  const headerObject = {
    username: cookies.get("user_name") ? cookies.get("user_name") : null,
    user_id: cookies.get("user_id") ? cookies.get("user_id") : null,
    account_name: cookies.get("account_name") ? cookies.get("account_name") : null,
    account_id: cookies.get("account_id") ? cookies.get("account_id") : null,
    authHash: cookies.get("authHash") ? cookies.get("authHash") : null,
    sid: cookies.get("sid") ? cookies.get("sid") : null,
    baseurl: cookies.get("baseUrl") ? cookies.get("baseUrl") : null,
    id: cookies.get("id") ? cookies.get("id") : null,
  }

  const handleSearchTermUnitChange = (newValue) => {


    setSearchTermUnit(newValue);
    if (timerUnits.current) {
      clearTimeout(timerUnits?.current)
    }
    timerUnits.current = setTimeout(async () => {
      if (newValue?.length >= 3) {
        setLoadingUnits(true);

        const result = await unitAPI.getUnitList({
          params: {
            ...headerObject,
            name: newValue,
            page_size: 20,
            page: 1
          }

        })
        setLoadingUnits(false);

        if (result.status && result?.data?.data?.data) {
          setUnits(result?.data?.data?.data)
        }
      }
      else {
        setUnits([])
      }
    }, 1000)
  };

  const handleSearchTermSourceGeofenceChange = (newValue) => {

    setSearchTermSourceGeofence(newValue);
    if (timerSourceGeofences.current) {
      clearTimeout(timerSourceGeofences?.current)
    }
    timerSourceGeofences.current = setTimeout(async () => {
      if (newValue?.length >= 3) {
        setLoadingSourceGeofences(true);

        const result = await geofenceAPI.getGeofenceList({
          params: {
            ...headerObject,
            name: newValue,
            port_type_ids: [1, 3],
            page_size: 20,
            page: 1
          }

        })
        setLoadingSourceGeofences(false);

        if (result.status && result?.data?.data?.data) {
          setSourceGeofences(result?.data?.data?.data)
        }
      }
      else {
        setSourceGeofences([])
      }
    }, 1000)
  };
  const handleSearchTermDestinationGeofenceChange = (newValue) => {

    setSearchTermDestinationGeofence(newValue);
    if (timerDestinationGeofences.current) {
      clearTimeout(timerDestinationGeofences?.current)
    }
    timerDestinationGeofences.current = setTimeout(async () => {
      if (newValue?.length >= 3) {
        setLoadingDestinationGeofences(true);

        const result = await geofenceAPI.getGeofenceList({
          params: {
            ...headerObject,
            name: newValue,
            port_type_ids: [2, 3],
            page_size: 20,
            page: 1
          }

        })
        setLoadingDestinationGeofences(false);

        if (result.status && result?.data?.data?.data) {
          setDestinationGeofences(result?.data?.data?.data)
        }
      }
      else {
        setDestinationGeofences([])
      }
    }, 1000)
  };

  const handleSearchTermRouteChange = (newValue) => {

    setSearchTermRoute(newValue);
    if (timerRoutes.current) {
      clearTimeout(timerRoutes?.current)
    }
    timerRoutes.current = setTimeout(async () => {
      if (newValue?.length >= 3) {
        setLoadingRoutes(true);

        const result = await routeAPI.getRouteList({
          params: {
            ...headerObject,
            name: newValue,
            page_size: 20,
            page: 1
          }

        })
        setLoadingRoutes(false);

        if (result.status && result?.data?.data?.data) {
          setRoutes(result?.data?.data?.data)
        }
      }
      else {
        setRoutes([])
      }
    }, 1000)
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "100% !important",
      }}
    >
      <SearchInput
        value={filters?.transaction_id}
        setValue={(value) => {
          if (!isNaN(value)) {
            setFilters({
              ...filters,
              transaction_id: value
            })
          }

        }}
        title={"#"}
      />
      <SearchInput
        value={filters?.transaction_number}
        setValue={(value) => {

          setFilters({
            ...filters,
            transaction_number: value
          })


        }}
        title={t('TRANSACTIONS.transaction_number')}
      />
      {noUnits ?
        <>
          <SearchInput
            value={filters?.unit_registration_number}
            setValue={(value) => {
              setFilters({
                ...filters,
                unit_registration_number: value
              })
            }}
            title={t('TRANSACTIONS.unit_registration_number')}
          />
          <SearchInput
            value={filters?.driver_name}
            setValue={(value) => {
              setFilters({
                ...filters,
                driver_name: value
              })
            }}
            title={t('TRANSACTIONS.driver_name')}
          />
        </> :
        <>
          {showUnitTransactionType ? null :
            <Autocomplete
              sx={{
                ml: 1.5, mt: 1, mb: 1, width: '95%',
                '& .MuiFormLabel-root,& .MuiInputBase-input': {
                  fontFamily: 'Cairo-Medium'
                }
              }}
              onInputChange={(event, newInputValue) => {

                handleSearchTermUnitChange(newInputValue);
              }}
              inputValue={searchTermUnit}
              loading={loadingUnits}
              loadingText={t('GLOBAL.searching')}
              value={filters?.unit}
              options={units?.length ? units : []}
              getOptionLabel={(option) => {

                return option && option?.name || ""
              }}
              onChange={(e, v, r) => {

                setFilters({
                  ...filters,
                  unit: v,
                  unit_id: v ? v?.id : ""
                })
              }}
              renderInput={(params) => (
                <TextField {...params}
                  placeholder={t('TRANSACTIONS.unit')}
                  variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
              )}
            />}
          <Autocomplete
            sx={{
              ml: 1.5, mt: 1, mb: 1, width: '95%',
              '& .MuiFormLabel-root,& .MuiInputBase-input': {
                fontFamily: 'Cairo-Medium'
              }
            }}
            value={filters?.unit_monitored_by_id_selected ? filters?.unit_monitored_by_id_selected : []}
            options={users?.length ? users : []}
            multiple={true}
            loading={loadingUsers}
            loadingText={t('GLOBAL.searching')}
            getOptionLabel={(option) => option && option?.name || ""}

            onChange={(e, v, r) => {
              setFilters({
                ...filters,
                unit_monitored_by_ids: v?.length > 0 ? v?.map(user => user?.id) : [],
                unit_monitored_by_id_selected: v
              })
            }}
            renderInput={(params) => (
              <TextField {...params}
                placeholder={t('TRANSACTIONS.unit_monitored_by_name_formatted')}
                variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
            )}
          />

        </>
      }
      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}

        loading={loadingUsers}
        loadingText={t('GLOBAL.searching')}
        value={filters?.auditedByName}
        options={users}
        getOptionLabel={(option) => {

          return option && option?.name || ""
        }}
        onChange={(e, v, r) => {

          setFilters({
            ...filters,
            auditedByName: v,
            audited_by_name: v ? v?.name : ""
          })
        }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('TRANSACTIONS.audited_by_name')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />

      <SearchInput
        value={filters?.note}
        setValue={(value) => {
          setFilters({
            ...filters,
            note: value
          })
        }}
        title={t('TRANSACTIONS.search_in_notes')}
      />
      <SearchInput
        value={filters?.destination_warehouse}
        setValue={(value) => {
          setFilters({
            ...filters,
            destination_warehouse: value
          })
        }}
        title={t('TRANSACTIONS.search_destination_geofence_name')}
      />
      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        value={filters?.transaction_type_2_id_selected ? filters?.transaction_type_2_id_selected : []}
        options={transactionType2List?.length ? transactionType2List : []}
        multiple={true}
        loading={loadingTransactionType2List}
        loadingText={t('GLOBAL.searching')}
        getOptionLabel={(option) => option && option?.name || ""}

        onChange={(e, v, r) => {
          setFilters({
            ...filters,
            transaction_type_2_ids: v?.length > 0 ? v?.map(user => user?.id) : [],
            transaction_type_2_id_selected: v
          })
        }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('TRANSACTIONS.transaction_type_2')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />

      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        value={filters?.transaction_status_selected ? filters?.transaction_status_selected : []}
        options={transactionStatuses?.length ? transactionStatuses : []}
        multiple={true}
        loading={loadingTransactionStatuses}
        loadingText={t('GLOBAL.searching')}
        getOptionLabel={(option) => option && option?.name || ""}

        onChange={(e, v, r) => {
          setFilters({
            ...filters,
            transaction_status_ids: v?.length > 0 ? v?.map(transaction_status => transaction_status?.id) : [],
            transaction_status_selected: v
          })
        }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('TRANSACTIONS.transaction_statuses')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />
      <CustomDateRange
        dateRange={filters}
        setDateRange={(value) => {
          setFilters({
            ...filters,
            ...value
          })
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >

        <Box
          sx={{
            backgroundColor: "lightgray",
            display: "flex",
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: "start",
            ml: 1.5,
            mt: 1,
            mb: 1,
            width: "100%",
            "& .MuiTypography-root": {
              fontFamily: "Cairo",
            },
          }}
          className="px-2 rounded"
        >
          <Typography>
            {t('TRANSACTIONS.branches_general')}
          </Typography>
          <Autocomplete
            sx={{
              ml: 1.5, mt: 1, mb: 1, width: '95%',
              '& .MuiFormLabel-root,& .MuiInputBase-input': {
                fontFamily: 'Cairo-Medium'
              }
            }}
            value={filters?.branches_selected ? filters?.branches_selected : []}
            options={branches?.length ? branches : []}
            multiple={true}
            loading={loadingBranches}
            loadingText={t('GLOBAL.searching')}
            getOptionLabel={(option) => option && option?.name || ""}

            onChange={(e, v, r) => {
              setFilters({
                ...filters,
                branch_ids: v?.length > 0 ? v?.map(branch => branch?.id) : [],
                branches_selected: v
              })
            }}
            renderInput={(params) => (
              <TextField {...params}
                placeholder={t('TRANSACTIONS.branches_general')}
                variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
            )}
          />

          <RadioGroup
            row={false}
            sx={{
              width: "100%",
              "& .MuiTypography-root": {
                fontFamily: "Cairo",
              },
            }}
            value={filters?.branch_type}
            onChange={(e) => {
              setFilters({
                ...filters,
                branch_type: e?.target.value
              })

            }}
          >
            <FormControlLabel
              value={1}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("TRANSACTIONS.source_branch")}
            />
            <FormControlLabel
              value={0}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("TRANSACTIONS.destination_branch")}
            />
            <FormControlLabel
              value={-1}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("TRANSACTIONS.all")}
            />


          </RadioGroup>
        </Box>
      </Box>


      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        value={filters?.port_categories_selected ? filters?.port_categories_selected : []}
        options={portCategories?.length ? portCategories : []}
        multiple={true}
        loading={loadingPortCategories}
        loadingText={t('GLOBAL.searching')}
        getOptionLabel={(option) => option && option?.name || ""}

        onChange={(e, v, r) => {
          setFilters({
            ...filters,
            port_category_ids: v?.length > 0 ? v?.map(branch => branch?.id) : [],
            port_categories_selected: v
          })
        }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('TRANSACTIONS.destination_port_categories')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />
      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}

        loading={loadingTransactionProductTypes}
        loadingText={t('GLOBAL.searching')}
        value={filters?.productType}
        options={transactionProductTypes}
        getOptionLabel={(option) => {

          return option && option?.name || ""
        }}
        onChange={(e, v, r) => {

          setFilters({
            ...filters,
            productType: v,
            product_type_id: v ? v?.id : ""
          })
        }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('TRANSACTIONS.product_type')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />


      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        onInputChange={(event, newInputValue) => {

          handleSearchTermSourceGeofenceChange(newInputValue);
        }}
        inputValue={searchTermSourceGeofence}
        loading={loadingSourceGeofences}
        loadingText={t('GLOBAL.searching')}
        value={filters?.sourceGeofence}
        options={sourceGeofences?.length ? sourceGeofences : []}
        getOptionLabel={(option) => {

          return option && option?.name || ""
        }}
        onChange={(e, v, r) => {

          setFilters({
            ...filters,
            sourceGeofence: v,
            source_geofence_id: v ? v?.id : ""
          })
        }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('TRANSACTIONS.source_geofence')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />
      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        onInputChange={(event, newInputValue) => {

          handleSearchTermDestinationGeofenceChange(newInputValue);
        }}
        inputValue={searchTermDestinationGeofence}
        loading={loadingDestinationGeofences}
        loadingText={t('GLOBAL.searching')}
        value={filters?.destinationGeofence}
        options={destinationGeofences?.length ? destinationGeofences : []}

        getOptionLabel={(option) => {

          return option && option?.name || ""
        }}



        onChange={(e, v, r) => {

          setFilters({
            ...filters,
            destinationGeofence: v,
            destination_geofence_id: v ? v?.id : ""
          })
        }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('TRANSACTIONS.destination_geofence')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />
      {/* <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        onInputChange={(event, newInputValue) => {

          handleSearchTermRouteChange(newInputValue);
        }}
        inputValue={searchTermRoute}
        loading={loadingRoutes}
        loadingText={t('GLOBAL.searching')}
        value={filters?.route}
        options={routes}

        getOptionLabel={(option) => {

          return option && option?.name || ""
        }}



        onChange={(e, v, r) => {

          setFilters({
            ...filters,
            route: v,
            route_id: v ? v?.id : ""
          })
        }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('TRANSACTIONS.route')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      /> */}
      {/* {showUnitTransactionType ? null : */}

      {/* } */}


      {/* <Box
        sx={{
          display: "flex",

          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >

        <Box
          sx={{
            backgroundColor: "lightgray",
            display: "flex",
            flexDirection: 'column',
            alignItems: "center",
            justifyContent: "start",
            ml: 1.5,
            mt: 1,
            mb: 1,
            width: "100%",
            "& .MuiTypography-root": {
              fontFamily: "Cairo",
            },
          }}
          className="px-2 rounded"
        >
          <Typography>
            {t('TRANSACTIONS.carrier')}
          </Typography>

          <RadioGroup
            row={false}
            sx={{
              width: "100%",
              "& .MuiTypography-root": {
                fontFamily: "Cairo",
              },
            }}
            value={filters?.carrier}
            onChange={(e) => {
              setFilters({
                ...filters,
                carrier: e?.target.value
              })

            }}
          >
            <FormControlLabel
              value={1}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("TRANSACTIONS.carrier_governmental")}
            />
            <FormControlLabel
              value={0}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("TRANSACTIONS.carrier_private")}
            />
            <FormControlLabel
              value={-1}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("TRANSACTIONS.all")}
            />


          </RadioGroup>
        </Box>
      </Box> */}

      {noUnits ? null : <>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >

          <Box
            sx={{
              backgroundColor: "lightgray",
              display: "flex",
              flexDirection: 'column',
              alignItems: "center",
              justifyContent: "start",
              ml: 1.5,
              mt: 1,
              mb: 1,
              width: "100%",
              "& .MuiTypography-root": {
                fontFamily: "Cairo",
              },
            }}
            className="px-2 rounded"
          >
            <Typography>
              {t('TRANSACTIONS.unit_is_monitored')}
            </Typography>

            <RadioGroup
              row={false}
              sx={{
                width: "100%",
                "& .MuiTypography-root": {
                  fontFamily: "Cairo",
                },
              }}
              value={filters?.unit_is_monitored}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  unit_is_monitored: e?.target.value
                })

              }}
            >
              <FormControlLabel
                value={1}
                control={<Radio sx={{ fontFamily: "Cairo" }} />}
                label={t("TRANSACTIONS.unit_is_monitored")}
              />
              <FormControlLabel
                value={0}
                control={<Radio sx={{ fontFamily: "Cairo" }} />}
                label={t("TRANSACTIONS.unit_is_not_monitored")}
              />
              <FormControlLabel
                value={-1}
                control={<Radio sx={{ fontFamily: "Cairo" }} />}
                label={t("TRANSACTIONS.all")}
              />


            </RadioGroup>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >

          <Box
            sx={{
              backgroundColor: "lightgray",
              display: "flex",
              flexDirection: 'column',
              alignItems: "center",
              justifyContent: "start",
              ml: 1.5,
              mt: 1,
              mb: 1,
              width: "100%",
              "& .MuiTypography-root": {
                fontFamily: "Cairo",
              },
            }}
            className="px-2 rounded"
          >
            <Typography>
              {t('TRANSACTIONS.is_monitored')}
            </Typography>

            <RadioGroup
              row={false}
              sx={{
                width: "100%",
                "& .MuiTypography-root": {
                  fontFamily: "Cairo",
                },
              }}
              value={filters?.is_monitored}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  is_monitored: e?.target.value
                })

              }}
            >
              <FormControlLabel
                value={1}
                control={<Radio sx={{ fontFamily: "Cairo" }} />}
                label={t("TRANSACTIONS.monitored")}
              />
              <FormControlLabel
                value={0}
                control={<Radio sx={{ fontFamily: "Cairo" }} />}
                label={t("TRANSACTIONS.not_monitored")}
              />
              <FormControlLabel
                value={-1}
                control={<Radio sx={{ fontFamily: "Cairo" }} />}
                label={t("TRANSACTIONS.all")}
              />


            </RadioGroup>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",

            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >

          <Box
            sx={{
              backgroundColor: "lightgray",
              display: "flex",
              flexDirection: 'column',
              alignItems: "center",
              justifyContent: "start",
              ml: 1.5,
              mt: 1,
              mb: 1,
              width: "100%",
              "& .MuiTypography-root": {
                fontFamily: "Cairo",
              },
            }}
            className="px-2 rounded"
          >
            <Typography>
              {t('TRANSACTIONS.has_violation')}
            </Typography>

            <RadioGroup
              row={false}
              sx={{
                width: "100%",
                "& .MuiTypography-root": {
                  fontFamily: "Cairo",
                },
              }}
              value={filters?.has_violation}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  has_violation: e?.target.value
                })

              }}
            >
              <FormControlLabel
                value={1}
                control={<Radio sx={{ fontFamily: "Cairo" }} />}
                label={t("TRANSACTIONS.has_violation")}
              />
              <FormControlLabel
                value={0}
                control={<Radio sx={{ fontFamily: "Cairo" }} />}
                label={t("TRANSACTIONS.doesnt_has_violation")}
              />
              <FormControlLabel
                value={-1}
                control={<Radio sx={{ fontFamily: "Cairo" }} />}
                label={t("TRANSACTIONS.all")}
              />


            </RadioGroup>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",

            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >

          <Box
            sx={{
              backgroundColor: "lightgray",
              display: "flex",
              flexDirection: 'column',
              alignItems: "center",
              justifyContent: "start",
              ml: 1.5,
              mt: 1,
              mb: 1,
              width: "100%",
              "& .MuiTypography-root": {
                fontFamily: "Cairo",
              },
            }}
            className="px-2 rounded"
          >
            <Typography>
              {t('TRANSACTIONS.is_complete')}
            </Typography>

            <RadioGroup
              row={false}
              sx={{
                width: "100%",
                "& .MuiTypography-root": {
                  fontFamily: "Cairo",
                },
              }}
              value={filters?.is_complete}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  is_complete: e?.target.value
                })

              }}
            >
              <FormControlLabel
                value={1}
                control={<Radio sx={{ fontFamily: "Cairo" }} />}
                label={t("TRANSACTIONS.is_complete")}
              />
              <FormControlLabel
                value={0}
                control={<Radio sx={{ fontFamily: "Cairo" }} />}
                label={t("TRANSACTIONS.not_complete")}
              />
              <FormControlLabel
                value={-1}
                control={<Radio sx={{ fontFamily: "Cairo" }} />}
                label={t("TRANSACTIONS.all")}
              />


            </RadioGroup>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",

            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >

          <Box
            sx={{
              backgroundColor: "lightgray",
              display: "flex",
              flexDirection: 'column',
              alignItems: "center",
              justifyContent: "start",
              ml: 1.5,
              mt: 1,
              mb: 1,
              width: "100%",
              "& .MuiTypography-root": {
                fontFamily: "Cairo",
              },
            }}
            className="px-2 rounded"
          >
            <Typography>
              {t('TRANSACTIONS.exceeded_allowed_time')}
            </Typography>

            <RadioGroup
              row={false}
              sx={{
                width: "100%",
                "& .MuiTypography-root": {
                  fontFamily: "Cairo",
                },
              }}
              value={filters?.exceeded_allowed_time}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  exceeded_allowed_time: e?.target.value
                })

              }}
            >
              <FormControlLabel
                value={1}
                control={<Radio sx={{ fontFamily: "Cairo" }} />}
                label={t("TRANSACTIONS.exceeded_allowed_time")}
              />
              <FormControlLabel
                value={0}
                control={<Radio sx={{ fontFamily: "Cairo" }} />}
                label={t("TRANSACTIONS.not_exceeded_allowed_time")}
              />
              <FormControlLabel
                value={-1}
                control={<Radio sx={{ fontFamily: "Cairo" }} />}
                label={t("TRANSACTIONS.all")}
              />


            </RadioGroup>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",

            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >

          <Box
            sx={{
              backgroundColor: "lightgray",
              display: "flex",
              flexDirection: 'column',
              alignItems: "center",
              justifyContent: "start",
              ml: 1.5,
              mt: 1,
              mb: 1,
              width: "100%",
              "& .MuiTypography-root": {
                fontFamily: "Cairo",
              },
            }}
            className="px-2 rounded"
          >
            <Typography>
              {t('TRANSACTIONS.has_stop_violation')}
            </Typography>

            <RadioGroup
              row={false}
              sx={{
                width: "100%",
                "& .MuiTypography-root": {
                  fontFamily: "Cairo",
                },
              }}
              value={filters?.has_stop_violation}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  has_stop_violation: e?.target.value
                })

              }}
            >
              <FormControlLabel
                value={1}
                control={<Radio sx={{ fontFamily: "Cairo" }} />}
                label={t("TRANSACTIONS.has_stop_violation")}
              />
              <FormControlLabel
                value={0}
                control={<Radio sx={{ fontFamily: "Cairo" }} />}
                label={t("TRANSACTIONS.doesnt_have_stop_violation")}
              />
              <FormControlLabel
                value={-1}
                control={<Radio sx={{ fontFamily: "Cairo" }} />}
                label={t("TRANSACTIONS.all")}
              />


            </RadioGroup>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",

            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >

          <Box
            sx={{
              backgroundColor: "lightgray",
              display: "flex",
              flexDirection: 'column',
              alignItems: "center",
              justifyContent: "start",
              ml: 1.5,
              mt: 1,
              mb: 1,
              width: "100%",
              "& .MuiTypography-root": {
                fontFamily: "Cairo",
              },
            }}
            className="px-2 rounded"
          >
            <Typography>
              {t('TRANSACTIONS.valid')}
            </Typography>

            <RadioGroup
              row={false}
              sx={{
                width: "100%",
                "& .MuiTypography-root": {
                  fontFamily: "Cairo",
                },
              }}
              value={filters?.valid}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  valid: e?.target.value
                })

              }}
            >
              <FormControlLabel
                value={1}
                control={<Radio sx={{ fontFamily: "Cairo" }} />}
                label={t("TRANSACTIONS.valid")}
              />
              <FormControlLabel
                value={0}
                control={<Radio sx={{ fontFamily: "Cairo" }} />}
                label={t("TRANSACTIONS.not_valid")}
              />
              <FormControlLabel
                value={-1}
                control={<Radio sx={{ fontFamily: "Cairo" }} />}
                label={t("TRANSACTIONS.all")}
              />


            </RadioGroup>
          </Box>
        </Box>

        {showUnitTransactionType ?
          <Box
            sx={{
              display: "flex",

              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >

            <Box
              sx={{
                backgroundColor: "lightgray",
                display: "flex",
                flexDirection: 'column',
                alignItems: "center",
                justifyContent: "start",
                ml: 1.5,
                mt: 1,
                mb: 1,
                width: "100%",
                "& .MuiTypography-root": {
                  fontFamily: "Cairo",
                },
              }}
              className="px-2 rounded"
            >
              <Typography>
                {t('TRANSACTIONS.unit_transaction_type')}
              </Typography>

              <RadioGroup
                row={false}
                sx={{
                  width: "100%",
                  "& .MuiTypography-root": {
                    fontFamily: "Cairo",
                  },
                }}
                value={filters?.unit_transaction_type}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    unit_transaction_type: e?.target.value
                  })

                }}
              >
                <FormControlLabel
                  value={1}
                  control={<Radio sx={{ fontFamily: "Cairo" }} />}
                  label={t("UNITS.inside_baghdad")}
                />
                <FormControlLabel
                  value={0}
                  control={<Radio sx={{ fontFamily: "Cairo" }} />}
                  label={t("UNITS.outside_baghdad")}
                />
                <FormControlLabel
                  value={2}
                  control={<Radio sx={{ fontFamily: "Cairo" }} />}
                  label={t("UNITS.unknown")}
                />
                <FormControlLabel
                  value={-1}
                  control={<Radio sx={{ fontFamily: "Cairo" }} />}
                  label={t("UNITS.all")}
                />


              </RadioGroup>
            </Box>
          </Box> : null}
      </>}
    </Box>
  );
};

export default Search