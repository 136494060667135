import { Box, Divider, Tooltip, Typography } from '@mui/material'
import React from 'react'
import AddIcon from "@mui/icons-material/Add";
import * as BiIcons from "react-icons/bi";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectRoles } from '../../reduxStore/RolesReducer';

function Title() {
    const [t] = useTranslation("common");
    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "stretch",
                    position: "relative",
                }}
            >
                

                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Typography
                        variant="h4"
                        sx={{
                            marginLeft: "10px",
                            marginBottom: "10px",
                            fontFamily: "Cairo-Bold",
                            fontSize: "23px",
                            letterSpacing: "-1px",
                        }}
                    >
                        {t("SIDEMENU.edit_and_update_main_unit_plate")}
                    </Typography>
                </Box>

                
            </Box>
            <Divider
                sx={{
                    backgroundColor: "#EBF2F7",
                    margin: "30px",
                    marginTop: "10px",
                    width: "100%",
                }}
                style={{
                    height: "3px",
                }}
            />
        </>
    )
}

export default Title