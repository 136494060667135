import helpers from "../../assets/js/helper";

const mainUnitObject = {
    id: "",
    name: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    plate_number: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    plate_letter: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    plate_type: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    plate_type_id: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    plate_province_id: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    plate_letter_id: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    wialon_id: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    tag_id: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    main_account_id: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    cls: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    mu: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    wialon_id: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    uacl: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    created_at: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    is_subscribed: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    is_installed: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    installation_date: {
        value: null,
        value_formated:"",
        error: false,
        message: "",
        required: false
    },
    note: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    numbering: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    receipt_number: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    company_name: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    driver_name: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    plate_province: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    name_and_type: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    enabled: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    tid: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    epc: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    national_id_number: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    name_and_type: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    car_registration_number: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    car_registration_date: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    car_registration_to_date: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    truck_vin_number: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    vin_number: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    takeel_number: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    takeel_by: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    created_date: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    expire_date: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    tag_id: {
        value: "",
        error: false,
        message: "",
        required: false
    }
    
}
export const resetErrors = (currentObject) => {
    var objectKeys = Object.keys(currentObject);

    var updatedObject = {
        ...currentObject
    }
    try {
        objectKeys.filter(key => key !== "id").map((key) => {

            updatedObject[key]["error"] = false
            updatedObject[key]["message"] = ""
        })
    } catch (error) {

    }

    return updatedObject

}
export const checkErrors = (errorMessages, currentObject) => {

    const errorMessagesKeys = Object.keys(errorMessages);
    errorMessagesKeys.map((key) => {
        if (!key?.includes('contacts')) {
            let messages = []
            errorMessages[key].map((message) => {
                messages.push(message)
            })

            currentObject[key]["error"] = true
            currentObject[key]["message"] = messages
        }
    })

    return currentObject
}
export const objectMerge = (updatedObject) => {
    const mergedObject = {
        ...updatedObject,
        id: updatedObject?.id,
        name: {
            value: updatedObject?.name,
            error: false,
            message: "",
            required: false
        },

        plate_number: {
            value: updatedObject?.plate_number,
            error: false,
            message: "",
            required: false
        },
        plate_type_id: {
            value: updatedObject?.plate_type,
            error: false,
            message: "",
            required: false
        },
        plate_province_id: {
            value: updatedObject?.plate_province,
            error: false,
            message: "",
            required: false
        },
        plate_letter_id: {
            value: updatedObject?.plate_letter,
            error: false,
            message: "",
            required: false
        },
        tag_id: {
            value: updatedObject?.tag,
            error: false,
            message: "",
            required: false
        },
        main_account_id: {
            value: updatedObject?.main_account,
            error: false,
            message: "",
            required: false
        },
        cls: {
            value: updatedObject?.cls,
            error: false,
            message: "",
            required: false
        },
        uacl: {
            value: updatedObject?.uacl,
            error: false,
            message: "",
            required: false
        },
        wialon_id: {
            value: updatedObject?.wialon_id,
            error: false,
            message: "",
            required: false
        },
        mu: {
            value: updatedObject?.mu,
            error: false,
            message: "",
            required: false
        },
        is_subscribed: {
            value: updatedObject?.is_subscribed,
            error: false,
            message: "",
            required: false
        },
        is_installed: {
            value: updatedObject?.is_installed,
            error: false,
            message: "",
            required: false
        },
        installation_date: {
            value: updatedObject?.installation_date,
            value_formated:updatedObject?.installation_date,
            error: false,
            message: "",
            required: false
        },
        note: {
            value: updatedObject?.note,
            error: false,
            message: "",
            required: false
        },
        numbering: {
            value: updatedObject?.numbering,
            error: false,
            message: "",
            required: false
        },
        receipt_number: {
            value: updatedObject?.receipt_number,
            error: false,
            message: "",
            required: false
        },
        company_name: {
            value: updatedObject?.company_name,
            error: false,
            message: "",
            required: false
        },
        driver_name: {
            value: updatedObject?.driver_name,
            error: false,
            message: "",
            required: false
        },
        plate_letter: {
            value: updatedObject?.plate_letter,
            error: false,
            message: "",
            required: false
        },
        plate_type: {
            value: updatedObject?.plate_type,
            error: false,
            message: "",
            required: false
        },
        plate_province: {
            value: updatedObject?.plate_province,
            error: false,
            message: "",
            required: false
        },
        name_and_type: {
            value: updatedObject?.name_and_type,
            error: false,
            message: "",
            required: false
        },
        enabled: {
            value: updatedObject?.enabled,
            error: false,
            message: "",
            required: false
        },
        tid: {
            value: updatedObject?.tid,
            error: false,
            message: "",
            required: false
        },
        epc: {
            value: updatedObject?.epc,
            error: false,
            message: "",
            required: false
        },
        national_id_number: {
            value: updatedObject?.national_id_number,
            error: false,
            message: "",
            required: false
        },
        name_and_type: {
            value: updatedObject?.name_and_type,
            error: false,
            message: "",
            required: false
        },
        car_registration_number: {
            value: updatedObject?.car_registration_number,
            error: false,
            message: "",
            required: false
        },
        car_registration_date: {
            value: updatedObject?.car_registration_date,
            error: false,
            message: "",
            required: false
        },
        car_registration_to_date: {
            value: updatedObject?.car_registration_to_date,
            error: false,
            message: "",
            required: false
        },
        truck_vin_number: {
            value: updatedObject?.truck_vin_number,
            error: false,
            message: "",
            required: false
        },
        vin_number: {
            value: updatedObject?.vin_number,
            error: false,
            message: "",
            required: false
        },
        takeel_number: {
            value: updatedObject?.takeel_number,
            error: false,
            message: "",
            required: false
        },
        takeel_by: {
            value: updatedObject?.takeel_by,
            error: false,
            message: "",
            required: false
        },
        created_date: {
            value: updatedObject?.created_date,
            value_formated: helpers.formatDateTime(updatedObject?.created_date),
            error: false,
            message: "",
            required: false
        },
        expire_date: {
            value: updatedObject?.expire_date,
            value_formated: helpers.formatDateTime(updatedObject?.expire_date),
            error: false,
            message: "",
            required: false
        },
        tag_id: {
            value: updatedObject?.tag_id,
            error: false,
            message: "",
            required: false
        },
        
    }

    return mergedObject;
}
export const resetObject = (currentObject, obj) => {
    // var objectKeys = Object.keys(currentObject);

    try {

        currentObject[obj]["error"] = false
        currentObject[obj]["message"] = ""
    } catch (error) {

    }

    return currentObject
}
export const viewObjectKeys = [
      "receipt_number"
    , "numbering"
    , "tid"
    , "epc"
    , "company_name"
    , "driver_name"
    , "national_id_number"
    , "name_and_type"
    , "plate_number"
    , "plate_letter"
    , "plate_type"
    , "plate_province"
    , "car_registration_number"
    , "car_registration_date"
    , "car_registration_to_date"
    , "vin_number"
    , "truck_vin_number"
    , "takeel_number"
    , "takeel_by"
    , "created_date"
    , "expire_date"
    , "note"
    , "wialon_name"
    , "update_note"
    , "installation_date"
    , "is_installed"
    , "is_subscribed"
    , "has_duplicate"
    , "account_name"
    , "installation_date_formatted"
    
]
export default mainUnitObject;