import { Card, CardContent, Grid } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';
import MainUnitCard from './EditAndUpdateMainUnitPlateCard';




export default function EditAndUpdateMainUnitPlateGrid({ mainUnits, updateOrEdit, loadData }) {
    const navigate = useNavigate();
    return (
        <div style={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Grid container spacing={3} wrap="wrap">
                {mainUnits?.map((mainUnit, index) => {
                    return (
                        <Grid item key={index + "_favourite_page"}>
                            <MainUnitCard
                                loadData={loadData}
                                mainUnit={mainUnit}
                                updateOrEdit={updateOrEdit}
                            />
                        </Grid>
                    )
                })}
            </Grid>
        </div>
    );
}
