import { Box, Button } from '@mui/material';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const RenderFooter = (props) => {
    const [t] = useTranslation("common");
    const gridtheme = useSelector((state) => state.themeData.gridtheme);
  
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box display="flex">
            
            <Button
              disabled={props?.isUploadingTime}
              variant="contained"
              // spacing={2}
              sx={{
                // flexGrow: 1,
                margin: 1,
                width: "120px !important",
                minWidth: "120px !important",
                maxWidth: "120px !important",
                backgroundColor: "#e7a52a",
                "&:hover": {
                  //   backgroundColor:SearchButtonTheme?.search_button_color+'88',
                },
                height: "35px",
                fontFamily: "Cairo-Bold",
              }}
              className="iconeFilterSearch"
              onClick={() => {
                props.submit();
              }}
            >
              {t("GLOBAL.Save_and_close")}
            </Button>
            
          </Box>
        </Box>
      </Box>
    );
  };

export default RenderFooter