import {
  Box,
  Button,
  IconButton,
  Tooltip,
  MenuItem,
  ListItemIcon,
  Typography,
} from "@mui/material";
import React, {
  useState,
  useEffect
} from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Grid from '../../Grid/Grid'
import PopupForm from "../../../Components/PopupForm";
import Cookies from "universal-cookie";
import mainUnitApi from "../../../Network/MainUnit";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const cookies = new Cookies();





const RenderContent = (props) => {
  const [t] = useTranslation("common");


  const handlePageChange = (newPage) => {
    props?.setPageNumber(newPage + 1);
  };
  const screenwidth = useSelector((state) => state.settingsData.screenwidth);
  const [columns, setColumns] = useState([
    { field: "id", headerName: "ت", minWidth: 50, maxWidth: 60 },
    {
      headerName: t("UNITS.details"), field: "details", flex: 3,
      renderCell: (rows) => {
        const textItems = rows?.row?.details?.split("تم").filter(item => item.trim());
        console.log(textItems);

        const values = <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%',marginBottom:'10' }}>
          {textItems?.map((item, index) => (

            <Typography key={index} variant="body1" sx={{marginBottom:'10'}} dir="rtl">
              تم {item?.trim()}
            </Typography>

          ))}
        </Box>
        let values_ = textItems?.map((item, index) => (item?.trim()))
        return values

      }


    },
    {
      headerName: t("UNITS.name"), field: "name", flex: 1
    },

    { headerName: t("UNITS.formatted_created_at"), field: "formatted_created_at", flex: 1 },



  ]);



  return (
    <Box className="Container-fluid">
      <Box className="row d-flex justify-content-center align-items-start">
        <Box className="col-12 col-sm-12 col-md-12 my-3 px-0"
          sx={{
            '& .MuiCheckbox-root': {
              color: '#1e6a99 !important',
              fill: '#1e6a99 !important'

            }
          }}
        >
          <Grid
            rows={props?.data}
            columns={columns}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0
                ? 'even-row'
                : 'odd-row'
            }
            setColumns={setColumns}
            pageSize={props?.pageSize}
            setPageSize={props?.setPageSize}
            pageNumber={props?.pageNumber}
            setPageNumber={props?.setPageNumber}
            handlePageChange={handlePageChange}
            rowsTotal={props?.data?.length}
            pageCount={props?.pageCount}
            filterChilds={null}
            notHaveAdvancSearch={true}
            // handleRowCellChange={handleRowCellChange}
            paginationMode={"client"}
          />
        </Box>
      </Box>
    </Box>
  );
};

const RenderFooter = (props) => {
  const [t] = useTranslation("common");
  const gridtheme = useSelector((state) => state.themeData.gridtheme);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box display="flex">
          <Button
            variant="outlined"
            // spacing={2}
            sx={{
              width: "50% !important",
              // flexGrow: 1,
              minWidth: "80px !important",
              maxWidth: "80px !important",
              margin: 1,
              backgroundColor: "#f7f7f7",
              borderColor: gridtheme?.colorWhite,
              color: gridtheme?.colorblack,
              boxShadow: "0 0 7px -2px white",
              //   color:SearchButtonTheme?.clear_button_text_color,

              "&:hover": {
                backgroundColor: "#f7f7f7",
                borderColor: gridtheme?.colorWhite,
                color: gridtheme?.colorblack,
                boxShadow: "0 0 7px 1px white",
                boxShadow: "0",
              },
              height: "35px",
              fontFamily: "Cairo-Bold",
            }}
            className="iconeFilterClear"
            color="secondary"
            onClick={() => {
              props?.setOpen(false);
            }}
          >
            {t("GLOBAL.close")}
          </Button>

        </Box>
      </Box>
    </Box>
  );
};

export default function MainUnitActivities(props) {
  const [t] = useTranslation("common");
  const [data, setData] = useState([])
  const [pageSize, setPageSize] = useState(5);
  const [pageNumber, setPageNumber] = useState(1);
  const [rowsTotal, setRowsTotal] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const headerObject = {
    username: cookies.get("user_name") ? cookies.get("user_name") : null,
    user_id: cookies.get("user_id") ? cookies.get("user_id") : null,
    account_name: cookies.get("account_name") ? cookies.get("account_name") : null,
    account_id: cookies.get("account_id") ? cookies.get("account_id") : null,
    authHash: cookies.get("authHash") ? cookies.get("authHash") : null,
    sid: cookies.get("sid") ? cookies.get("sid") : null,
    baseurl: cookies.get("baseUrl") ? cookies.get("baseUrl") : null,
    id: cookies.get("id") ? cookies.get("id") : null,

  };

  useEffect(() => {
    if (props?.object)
      loadData()
  }, [props?.openForm, props?.object, pageNumber, pageSize]);


  const loadData = async () => {
    let data = null;
    try {
      data = await mainUnitApi.mainUnitActivities({
        params: {
          ...headerObject,
          main_unit_id: props?.object?.id,
          page: pageNumber,
          page_size: pageSize,

        }
      })
      if (data && data?.status && data?.data?.status) {
        setData(data?.data?.data?.data?.map((item, index) => {
          return {
            ...item,
            id: (index + 1)
          }
        }))
        setRowsTotal(data?.data?.data?.total_records);
        setPageCount(Math.ceil(data?.data?.data?.total_records / pageSize));
      } else {
        setData([])
        setRowsTotal(0);
        setPageCount(0);
        toast.warn(t('NETWORK_MESSAGES.messageError'))
      }
    } catch (error) {
      console.log(error?.message)
    }
  }


  return (
    <PopupForm
      isFullScreen={false}
      open={props?.openForm}
      setOpen={props?.setOpenForm}
      title={t("UNITS.show_activities")}
      content={
        <RenderContent
          setData={setData}
          data={data}
          setPageSize={setPageSize}
          setPageNumber={setPageNumber}
          pageSize={pageSize}
          pageNumber={pageNumber}
          pageCount={pageCount}
          open={props?.openForm}
          setOpen={props?.setOpenForm}
        />
      }
      footer={
        <RenderFooter
          open={props?.openForm}
          setOpen={props?.setOpenForm}
        />
      }
    />
  );
}
